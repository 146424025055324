<template>
  <div class="signin-page">
    <!-- Le logo et le titre -->
    <md-empty-state>
      <!-- La zone de debug -->
      <strong class="md-empty-state-label">Sélectionner votre cabinet</strong>
      <div v-for="orga in organizations" :key="orga.id">
        <md-button
          class="md-primary md-raised signin-button"
          @click.native="selectOrganization(orga.id)"
          >{{ orga.name }}</md-button
        >
      </div>
    </md-empty-state>
  </div>
</template>

<script>
import User from "../model/users.js";
export default {
  data() {
    return {
      isDebug: false,
      organizations: [],
    };
  },
  mounted: function() {
    User.getOrganizations(this.$store.state.self.email).then(
      (data) => (this.organizations = data)
    );
  },
  computed: {
    logo() {
      return window.localStorage.getItem("logo");
    },
  },
  methods: {
    selectOrganization(orgaId) {
      this.$store.dispatch("SELECT_ORGANIZATION", orgaId).then(() => {
        this.$router.push("/dashboard/");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.signin-page {
  height: 100vh;
  display: flex;
  justify-content: space-around;

  .signin-button {
    width: 90%;
    padding: 10px;
    min-width: 200px;
    color: var(--text-primary) !important;
  }

  a {
    margin-top: 16px;
  }
}
</style>
